<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <!-- Table Container Card -->
    <b-card no-body>
    
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <!-- <label>Status</label> -->
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sites"
              label="site_name"
              class="w-100"
              placeholder="Project Site"
              @input="filterTable"
              v-model="siteData"
              :clearable="false"
            >
              <template #selected-option="{ site_name }">
                <span v-if="site_name.length < 23">{{site_name}}</span>
                <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '..'}}</span>
              </template>
            </v-select>
          </b-col>

          <b-col md="4"  class="mobile_tab_max_width_flex">
                
            
            <b-form-select v-model="role" @change="filterTable">

              <b-form-select-option value="" disabled>Select Role</b-form-select-option>
              
              <b-form-select-option :value="role.role" v-for="role in roles" :key="role._id">{{role.name}}</b-form-select-option>
              
            </b-form-select>
                
            
          </b-col>

          
          <!-- <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2 d-flex align-items-center justify-content-end">
           
            

          </b-col> -->
          
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input only_search_mobile_margin_bottom_zero"
                placeholder="Search..."
                @input="filterTable"
              />
              <b-button
                v-if="checkPermission($route.name, 'Add') && showAddOption()"
                variant="warning"
                class="mobile-margin-bottom mobile_tab_margin_bottom_zero"
                :to="{name:'add-client-user'}"
              >
                <span class="text-nowrap">Add</span>
              </b-button>
              <b-button
                v-if="checkPermission($route.name, 'Delete')"
                variant="danger"
                class="ml-1 mobile-margin-bottom"
                :disabled="selected && selected.length > 0 ? false:true"
                @click="changeMultipleStatus('deleted')"
              >
                <span class="text-nowrap">Delete</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative bigTableFH common_big_tbl"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >
       <template #head(checkbox)="items">
          <b-form-checkbox class="custom-control-primary" v-model="selectAll" @change="selectall"/>
        </template>

         <template #cell(info)="items">             
            <feather-icon
              :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
              @click="items.toggleDetails"
            />
        </template>

        <template #cell(checkbox)="items">
           
            <b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-primary" />
         
        </template>
        <!-- Column: User -->

        <template #cell(profile_image)="items">
           
            <!-- <img :src="items.item.profile_thumbnail+'?'+new Date()" v-if="items.item.profile_thumbnail != null" width="100" @click="openImage(items.item.profile_image)">
            <img :src="doc_icon" v-if="items.item.profile_thumbnail == null" width="100"> -->
            <div @click="openImage(items.item.profile_image)" :key="items.index +''+ Math.random()">
              <b-img-lazy v-bind="mainthumbProps" :src="items.item.profile_thumbnail+'?'+new Date()" class="rounded" v-if="items.item.profile_thumbnail != null" height="65" width="65" />

              <b-img-lazy v-bind="mainthumbProps" v-if="items.item.profile_thumbnail == null" :src="doc_icon" class="rounded" height="65" width="65" />
            </div>
            
        </template>

        <template #cell(full_name)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.full_name | capitalize}}</span>
         
        </template>

        <!-- Column: Role -->
        <template #cell(username)="items">
           
            <span class="wordBreak"  @click="items.toggleDetails">{{ items.item.username}}</span>
         
        </template>

        <template #cell(role)="items">
           
            <span class="wordBreak"  @click="items.toggleDetails">{{ items.item.role | removeUnderCapitalize}}</span>
         
        </template>

        <template #cell(job_title)="items">
           
            <span class="wordBreak"  @click="items.toggleDetails">{{ items.item.job_title ? items.item.job_title.job_title : 'N/A'}}</span>
         
        </template>

        <template #cell(site_name)="items">
           
            <span class="wordBreak" v-if="items.item.site_name != null">{{ items.item.site_name | capitalize}}</span>
            <span class="wordBreak" v-if="items.item.site_name == null && (items.item.role == 'operation_manager' || items.item.role == 'operation_executive' || items.item.role == 'supervisor'|| items.item.role == 'crew' || items.item.role == 'site_manager' || items.item.role == 'site_client' || items.item.role == 'senior_operation_manager' || items.item.role == 'director' || items.item.role == 'admin')">
              
              <span v-b-tooltip.hover.v-primary.top :title="getSiteName(items.item.om_sites)" v-if="$store.getters.currentUser.role == 'administrator' && getSiteName(items.item.om_sites).split(',').length > 3">
                {{getSiteName2(items.item.om_sites)[0]}} 
                <b-badge v-if="getSiteName2(items.item.om_sites)[1] != ''" pill variant="primary"> +{{ getSiteName2(items.item.om_sites)[1]}} </b-badge>
              </span>
              <span v-if="$store.getters.currentUser.role == 'administrator' && getSiteName(items.item.om_sites).split(',').length <= 3">
                {{getSiteName2(items.item.om_sites)[0]}} 
              </span>

              <span v-b-tooltip.hover.v-primary.top :title="getFromAsgSite(items.item.om_sites)" v-if="$store.getters.currentUser.role != 'administrator' && getFromAsgSite(items.item.om_sites).split(',').length > 3">
                {{getFromAsgSite2(items.item.om_sites)[0]}}
                <b-badge v-if="getFromAsgSite2(items.item.om_sites)[1] != ''" pill variant="primary"> +{{ getFromAsgSite2(items.item.om_sites)[1]}} </b-badge>
              </span>
              <span v-if="$store.getters.currentUser.role != 'administrator' && getFromAsgSite(items.item.om_sites).split(',').length <= 3">
                {{getFromAsgSite2(items.item.om_sites)[0]}}
              </span>

            </span>
            
            <!-- <span class="wordBreak" v-if="items.item.role == 'admin'">{{ 'N/A'}}</span> -->
         
        </template>

        <template #cell(face_id)="items">
           
            <span class="wordBreak">{{ items.item.face_id != null ? 'Yes' : 'No'}}</span>
         
        </template>

        <template #cell(advanced_settings)="items">
           
            <span class="wordBreak">{{ items.item.advanced_settings | capitalize}}</span>
         
        </template>

        <template #cell(shift)="items">
              
          <span class="wordBreak" v-if="(items.item.role == 'attendance' || items.item.role == 'panel')">{{ items.item.shift ? items.item.shift.shift : 'N/A'}}</span>
          <span class="wordBreak" v-if="items.item.role == 'supervisor' || items.item.role == 'crew'">
            
            <span v-b-tooltip.hover.v-primary.top :title="shiftArray(items.item.supervisor_shift)" v-if="$store.getters.currentUser.role == 'administrator' && shiftArray(items.item.supervisor_shift).split(',').length > 3">
              {{shiftArray2(items.item.supervisor_shift)[0]}} 
              <b-badge v-if="shiftArray2(items.item.supervisor_shift)[1] != ''" pill variant="primary"> +{{ shiftArray2(items.item.supervisor_shift)[1]}} </b-badge>
            </span>
            <span v-if="$store.getters.currentUser.role == 'administrator' && shiftArray(items.item.supervisor_shift).split(',').length <= 3">
              {{shiftArray2(items.item.supervisor_shift)[0]}} 
            </span>

            <span v-b-tooltip.hover.v-primary.top :title="siteShiftArray(items.item.supervisor_shift)" v-if="$store.getters.currentUser.role != 'administrator' && siteShiftArray(items.item.supervisor_shift).split(',').length > 3">
              {{siteShiftArray2(items.item.supervisor_shift)[0]}}
              <b-badge v-if="siteShiftArray2(items.item.supervisor_shift)[1] != ''" pill variant="primary"> +{{ siteShiftArray2(items.item.supervisor_shift)[1]}} </b-badge>
            </span>
            <span v-if="$store.getters.currentUser.role != 'administrator' && siteShiftArray(items.item.supervisor_shift).split(',').length <= 3">
              {{siteShiftArray2(items.item.supervisor_shift)[0]}}
            </span>

          </span>

          <span class="wordBreak" v-if="items.item.role != 'supervisor' && items.item.role != 'crew' && items.item.role != 'attendance' && items.item.role != 'panel'">{{'N/A'}}</span>

        </template>

        <template #cell(status)="items">
            <b-badge pill :variant="items.item.status == 'active' ? 'success' : 'danger' " class="text-capitalize"  @click="items.toggleDetails">
                {{ items.item.status}}
            </b-badge>
        </template>

        

        <template #cell(created_at)="items">
           
            <!-- <span>{{ items.item.created_at | dateTime}}</span> -->
            
            <span @click="items.toggleDetails">{{sitedateTime(items.item.created_at,siteData.pdf_date_format,siteData.pdf_time_format)}}</span>
         
        </template>

        <template #cell(actions)="items">
          <!-- <b-dropdown
            v-if="((checkPermission($route.name, 'Edit') || checkPermission($route.name, 'Remove Face Id') || checkPermission($route.name, 'Change Status') || checkPermission($route.name, 'Delete')) && showOption(items.item.role))"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          > -->

            <!-- <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template> -->

            <!-- <b-dropdown-item v-if="checkPermission($route.name, 'View Profile')" :to="{ name: 'view-client-user', params: { id: items.item._id } }"> -->
            <!-- <b-dropdown-item :to="{ name: 'view-client-user', params: { id: items.item._id } }" v-if="checkPermission($route.name, 'View Profile')">
              <feather-icon icon="UserCheckIcon" />
              <span class="align-middle ml-50">View Profile</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="checkPermission($route.name, 'Edit')" :to="{ name: 'edit-client-user', params: { id: items.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="checkPermission($route.name, 'Duplicate')" :to="{ name: 'duplicate-client-user', params: { id: items.item._id } }">
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item>

            <b-dropdown-item @click="removeFace(items.item._id)" v-if="checkPermission($route.name, 'Remove Face Id') && items.item.face_id != null">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Remove Face Id</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="checkPermission($route.name, 'Change Status')" @click="statusChange(items.item)">
              <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
              <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
              <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="checkPermission($route.name, 'Delete')" @click="deleteItem(items.item._id)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            
          </b-dropdown> -->
          <!-- <b-dropdown 
            v-else
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
            >
            
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'view-client-user', params: { id: items.item._id } }">
              <feather-icon icon="UserCheckIcon" />
              <span class="align-middle ml-50">View Profile</span>
            </b-dropdown-item>

          </b-dropdown>  -->

          <b-row>
             <b-col class="p-0 pb-1 mobile_col" cols="3" v-if="checkPermission($route.name, 'View Profile')">
                <b-link  :to="{ name: 'view-client-user', params: { id: items.item._id } }" v-if="checkPermission($route.name, 'View Profile')"
                  v-b-tooltip.hover.v-warning
                  title="View Profile"
                  variant="outline-warning"
                >
                    <feather-icon icon="UserCheckIcon" class="mediumSize mr-1"/>
                    
                </b-link>
             </b-col>
            <b-col class="p-0 pb-1 mobile_col" cols="3" v-if="(checkPermission($route.name, 'Edit')) &&  rolesEditable.some(editableRole => editableRole.role.toLowerCase() ===  items.item.role.toLowerCase())">
                <b-link  v-if="checkPermission($route.name, 'Edit')" :to="{ name: 'edit-client-user', params: { id: items.item._id } }"
                  v-b-tooltip.hover.v-warning
                  title="Edit"
                  variant="outline-warning"
                >
                    <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
                    
                </b-link>
             </b-col>

            <b-col class="p-0 pb-1 mobile_col" cols="3" v-if="checkPermission($route.name, 'Duplicate')">
                <b-link v-if="checkPermission($route.name, 'Duplicate')" :to="{ name: 'duplicate-client-user', params: { id: items.item._id } }"
                  v-b-tooltip.hover.v-warning
                  title="Duplicate"
                  variant="outline-warning"
                >
                    <feather-icon icon="CopyIcon" class="mediumSize mr-1"/>
                  </b-link>
            </b-col>

            <b-col class="p-0 pb-1 mobile_col" cols="3" v-if="checkPermission($route.name, 'Change Status')">
                <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" v-if="checkPermission($route.name, 'Change Status')" @click="statusChange(items.item)">
                  <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
              </b-link>
             </b-col>

            <b-col class="p-0 pb-1 mobile_col" cols="3" v-if="checkPermission($route.name, 'Remove Face Id') && items.item.face_id != null">
                  <b-link @click="removeFace(items.item._id)" v-if="checkPermission($route.name, 'Remove Face Id') && items.item.face_id != null"
                  v-b-tooltip.hover.v-warning
                  title="Remove Face Id"
                  variant="outline-warning"
                >
                    <feather-icon icon="XIcon" class="mediumSize mr-1"/>
                    
                </b-link>
             </b-col>

            <b-col class="p-0 pb-1 mobile_col" cols="3" v-if="checkPermission($route.name, 'Delete')">
                 <b-link  v-if="checkPermission($route.name, 'Delete')" @click="deleteItem(items.item._id)" 
                    v-b-tooltip.hover.v-warning
                    title="Delete"
                    variant="outline-warning"
                  >
                      <feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
                      
                  </b-link>
             </b-col>
          </b-row>
        </template>

         <template #row-details="items">
            <div class="p-1 px-2 fbDetail mb-1">
                <b-row>

                  <b-col cols="12" xl="12" >
                      <table class="mt-2 mt-xl-0 w-100">
                          <tr class="mb-1">
                              <th class="pb-50" style="width: 10%">
                                <div class="d-flex align-items-center">
                          
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">
                                      Username
                                    </h6>
                                  <!--  <small>Monthly Sales</small> -->
                                  </div>
                                </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 5%;padding-top: 8px;">
                                  <span class="wordBreak">{{ items.item.username }}</span>
                              </td>
                              <!-- <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                  <span class="wordBreak" v-if="items.item.site_name != null">{{ items.item.site_name | capitalize}}</span>
                                  <span class="wordBreak" v-if="items.item.site_name == null && (items.item.role == 'operation_manager' || items.item.role == 'operation_executive' || items.item.role == 'supervisor'|| items.item.role == 'crew' || items.item.role == 'site_manager' || items.item.role == 'site_client' || items.item.role == 'admin')">
                                    
                                    <span v-b-tooltip.hover.v-primary.top :title="getSiteName(items.item.om_sites)" v-if="$store.getters.currentUser.role == 'administrator' && getSiteName(items.item.om_sites).split(',').length > 3">
                                      {{getSiteName2(items.item.om_sites)[0]}} 
                                      <b-badge v-if="getSiteName2(items.item.om_sites)[1] != ''" pill variant="primary"> +{{ getSiteName2(items.item.om_sites)[1]}} </b-badge>
                                    </span>
                                    <span v-if="$store.getters.currentUser.role == 'administrator' && getSiteName(items.item.om_sites).split(',').length <= 3">
                                      {{getSiteName2(items.item.om_sites)[0]}} 
                                    </span>

                                    <span v-b-tooltip.hover.v-primary.top :title="getFromAsgSite(items.item.om_sites)" v-if="$store.getters.currentUser.role != 'administrator' && getFromAsgSite(items.item.om_sites).split(',').length > 3">
                                      {{getFromAsgSite2(items.item.om_sites)[0]}}
                                      <b-badge v-if="getFromAsgSite2(items.item.om_sites)[1] != ''" pill variant="primary"> +{{ getFromAsgSite2(items.item.om_sites)[1]}} </b-badge>
                                    </span>
                                    <span v-if="$store.getters.currentUser.role != 'administrator' && getFromAsgSite(items.item.om_sites).split(',').length <= 3">
                                      {{getFromAsgSite2(items.item.om_sites)[0]}}
                                    </span>

                                  </span>
                              </td> -->

                               <th class="pb-50" style="width: 5%">
                                <div class="d-flex align-items-center">
                          
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">
                                      Shift
                                    </h6>
                                  <!--  <small>Monthly Sales</small> -->
                                  </div>
                                </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                  <span class="wordBreak" v-if="(items.item.role == 'attendance' || items.item.role == 'panel')">{{ items.item.shift ? items.item.shift.shift : 'N/A'}}</span>
                                  <span class="wordBreak" v-if="items.item.role == 'supervisor' || items.item.role == 'crew'">
                                    
                                    <span v-b-tooltip.hover.v-primary.top :title="shiftArray(items.item.supervisor_shift)" v-if="$store.getters.currentUser.role == 'administrator' && shiftArray(items.item.supervisor_shift).split(',').length > 3">
                                      {{shiftArray2(items.item.supervisor_shift)[0]}} 
                                      <b-badge v-if="shiftArray2(items.item.supervisor_shift)[1] != ''" pill variant="primary"> +{{ shiftArray2(items.item.supervisor_shift)[1]}} </b-badge>
                                    </span>
                                    <span v-if="$store.getters.currentUser.role == 'administrator' && shiftArray(items.item.supervisor_shift).split(',').length <= 3">
                                      {{shiftArray2(items.item.supervisor_shift)[0]}} 
                                    </span>

                                    <span v-b-tooltip.hover.v-primary.top :title="siteShiftArray(items.item.supervisor_shift)" v-if="$store.getters.currentUser.role != 'administrator' && siteShiftArray(items.item.supervisor_shift).split(',').length > 3">
                                      {{siteShiftArray2(items.item.supervisor_shift)[0]}}
                                      <b-badge v-if="siteShiftArray2(items.item.supervisor_shift)[1] != ''" pill variant="primary"> +{{ siteShiftArray2(items.item.supervisor_shift)[1]}} </b-badge>
                                    </span>
                                    <span v-if="$store.getters.currentUser.role != 'administrator' && siteShiftArray(items.item.supervisor_shift).split(',').length <= 3">
                                      {{siteShiftArray2(items.item.supervisor_shift)[0]}}
                                    </span>

                                    </span>

                                    <span class="wordBreak" v-if="items.item.role != 'supervisor' && items.item.role != 'crew' && items.item.role != 'attendance' && items.item.role != 'panel'">{{'N/A'}}</span>
                              </td>

                              <th class="pb-50" style="width: 10%">
                                <div class="d-flex align-items-center">
                          
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">
                                      Face ID Updated
                                    </h6>
                                  <!--  <small>Monthly Sales</small> -->
                                  </div>
                                </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 5%;padding-top: 8px;">
                                  <span class="wordBreak">{{ items.item.face_id != null ? 'Yes' : 'No'}}</span>
                              </td>
                          </tr>
                          <tr>
                            <th class="pb-50" style="width: 8%">
                                <div class="d-flex align-items-center">
                          
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">
                                      Attendance
                                    </h6>
                                  <!--  <small>Monthly Sales</small> -->
                                  </div>
                                </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 5%;padding-top: 8px;">
                                  <span class="wordBreak">{{ items.item.advanced_settings | capitalize}}</span>
                              </td>

                              <th class="pb-50" style="width: 10%">
                                <div class="d-flex align-items-center">
                          
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">
                                      Account Type
                                    </h6>
                                  <!--  <small>Monthly Sales</small> -->
                                  </div>
                                </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 8%;padding-top: 8px;">
                                  <span class="wordBreak">{{ items.item.role | removeUnderCapitalize}}</span>
                              </td>

                               <th class="pb-50" style="width: 8%">
                                <div class="d-flex align-items-center">
                          
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">
                                      Job Title
                                    </h6>
                                  <!--  <small>Monthly Sales</small> -->
                                  </div>
                                </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 20%;padding-top: 8px;">
                                  <span class="wordBreak">{{ items.item.job_title ? items.item.job_title.job_title : 'N/A'}}</span>
                              </td>
                          </tr>
                          <tr class="mb-1">
                            <th class="pb-50" style="width: 16%">
                              <b-button
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="outline-secondary"
                                  @click="items.toggleDetails"
                                  size="sm"
                                  class="ml-1"
                                >
                                  Hide Detail
                                </b-button>
                            </th>
                            <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                
                            </td>
                            <th class="pb-50" style="width: 16%">
                            </th>
                            <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                
                            </td>
                            <th class="pb-50" style="width: 16%">
                            </th>
                            <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                
                            </td>
                          </tr>
                      </table>
                  </b-col>

                </b-row>
            </div>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal id="image-modal" ref="image-modal" centered title="Profile Image" hide-footer :no-close-on-backdrop="false">
      
      <template>
            <center>

              <div id="pluswrap" v-if="isLoadingImg">
                <div class="plus">
                  <b-spinner
                    style="width: 3rem; height: 3rem;"
                    class="mr-1"
                    label="Large Spinner"
                    variant="warning"
                  />
                </div>
              </div>
              

              <img v-show="isLoaded" :src="img" alt="Profile Image" style="max-width: 460px;height: auto" @load="isLoadingImg = false; isLoaded = true">

            </center>
            
      </template>
   
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, VBTooltip,BImgLazy,BFormCheckbox,
  BBadge, BDropdown, BDropdownItem, BPagination,BCardBody,BFormSelect,BFormSelectOption,BBreadcrumb,BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"

export default {
  components: {
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BFormSelect,
    BFormSelectOption,
    BBreadcrumb,
    BImgLazy,
    BSpinner
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
       tableColumns: [
            { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '1%',fontSize:'10px'}},
            { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '1%' } },
            { key: 'profile_image', label: 'Profile Image', sortable: false , thStyle:  {width: '1%'/*,fontSize:'10px'*/}},
            { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '15%'/* ,fontSize:'10px' */}},
            { key: 'site_name', label: 'Project Site Name', sortable: false , thStyle:  {width: '20%'/*,fontSize:'10px'*/}},
            { key: 'full_name', label: 'Name', sortable: true , thStyle:  {width: '20%'/*,fontSize:'10px'*/}},
            // { key: 'username', label: 'Username', sortable: true , thStyle:  {width: '20%'/*,fontSize:'10px'*/}},
            // { key: 'role', label: 'Account Type', sortable: true , thStyle:  {width: '5%'/*,fontSize:'10px'*/}},
            // { key: 'job_title', label: 'Job Title', sortable: false , thStyle:  {width: '10%'/*,fontSize:'10px'*/}},
            
            // { key: 'shift', label: 'Shift', sortable: false , thStyle:  {width: '9%'/*,fontSize:'10px'*/}},
            
            // { key: 'face_id', label: 'Face Id Updated', sortable: true , thStyle:  {width: '9%'/*,fontSize:'10px'*/}},
            // { key: 'advanced_settings', label: 'Attendance', sortable: true , thStyle:  {width: '9%'/*,fontSize:'10px'*/}},
            
            { key: 'created_at', label: 'Signup Date', sortable: true , thStyle:  {width: '24%'/*,fontSize:'10px'*/}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '18%'/*,fontSize:'10px'*/}},
          ],
        
        items:[],
        isSortDirDesc: false,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        //sortBy : 'status',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        siteData:{
          _id:'all-site',
          site_name:'All Project Sites',
          pdf_date_format: 'DD MMM YYYY',
          pdf_time_format: 'HH:mm'
        },
        sites:[],
        doc_icon: require('@/assets/images/avatars/default.png'),
        role:'',
        roles:[],
        rolesEditable:[],
        img:null,
        mainProps: {
          center: true,
          fluidGrow: true,
          blank: true,
          blankColor: '#ffff',
          width: 600,
          height: 500,
          
        },
        selectAll:false,
        selected:[],
        mainthumbProps: {
          blank: true,
          blankColor: '#ffff',
          width: 75,
          height: 75,
          
        },
        isLoadingImg:true,
        isLoaded:false
    }
  },
  methods : {
    selectall(e){

      this.selected = [];
      
      if (e) {
        var selected = [];

        this.items.forEach(function(item){
            selected.push(item._id);
        })

        this.selected = selected;

      }
    },
    changeMultipleStatus(status){
       this.decisionAlert('Are you sure you want to delete selected record?')
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: this.selected,
                          status:status
                        },
                    api:"/api/delete-multiple-users",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            this.successAlert();
                            
                            this.selected = [];
                            this.selectAll = false;
                  
                            this.$refs.refUserListTable.refresh();
                          }
                      });
                      
              }
          })
          .catch(err => {
              // An error occurred
          })   
    },
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     site:this.siteData ? this.siteData._id:null,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     filter_role:this.role
                   },
                   api: '/api/client-user-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = [];
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    statusChange(item){
      if (item.status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='User Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='User Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-client-user-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },

    deleteItem(id){
      var msg = 'Are you sure want to delete this record?';
      var message='User Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                          status:'deleted'
                        },
                    api:"/api/change-client-user-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              // An error occurred
          })
    },
    allSites(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     role:this.$store.getters.currentUser.role,
                   },
                   api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.sites = this.$store.getters.getResults.data;

                        // if (this.sites.length == 1 ) {
                        //     this.siteData = this.sites[0];

                        //     this.filterTable();
                        // }

                        var obj = {
                          _id:'all-site',
                          site_name:'All Project Sites',
                          pdf_date_format: 'DD MMM YYYY',
                          pdf_time_format: 'HH:mm'
                        }
                        this.sites.unshift(obj);

                        this.siteData = this.defaultSite(this.sites);
                        this.filterTable();
                        
                        return this.sites;
                    }
                });
    },
    getSiteName(item){
      var site_name = [];

      item.forEach(i => {
        site_name.push(i.site_name);
      })

      return site_name.join(', ');

    },
    getSiteName2(item){

      if(item.length == 0){
        return ['N/A'];
      }

      var site_name = [];

      item.forEach(i => {
        site_name.push(i.site_name);
      })

      if(site_name.length > 3){
        return [site_name[0] + ', ' + site_name[1] + ', ' + site_name[2], (site_name.length - 3)]
      } else {
        return [site_name.join(', '), ''];
      }

    },
    shiftArray(value){
      if (value.length > 0) {
        var shift = [];

          value.forEach(i => {
            shift.push(i.shift);
          })

          return shift.join(', ');

      }else{
        return 'N/A';
      }
    },
    shiftArray2(value){
      if (value.length > 0) {
        var shift = [];

          value.forEach(i => {
            shift.push(i.shift);
          })

          if(shift.length > 3){
            return [shift[0] + ', ' + shift[1] + ', ' + shift[2], (shift.length - 3)]  
          } else {
            return [shift.join(', '), ''];
          }

      }else{
        return 'N/A';
      }
    },
    removeFace(id){
      var msg = 'Are you sure want to remove face id for this record?';
      var message='Face Id Removed Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                        },
                    api:"/api/remove-face-id",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              // An error occurred
          })
    },
    getRoles(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role
           },
           api: '/api/get-roles'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.roles = this.$store.getters.getResults.data;
                return this.roles;
            }
        }); 
    },
    getRolesEditable(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
             type:'modify'
           },
           api: '/api/get-roles'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.rolesEditable = this.$store.getters.getResults.data;
                return this.rolesEditable;
            }
        }); 
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to: null, // hyperlink
        text: 'Users', // crumb text
        active:true
        
      }];
      return item;
    },
    siteShiftArray(shift){
      if (shift.length == 0) {
        return 'N/A';
      }else{
        var arr = [];
        for (var i = 0; i < shift.length; i++) {
          if (this.$store.getters.currentUser.om_sites.indexOf(shift[i].site) >= 0) {
            arr.push(shift[i].shift);
          }
        }
        return arr.join(', ');
      }
    },
    siteShiftArray2(shift){
      if (shift.length == 0) {
        return 'N/A';
      }else{
        var arr = [];
        for (var i = 0; i < shift.length; i++) {
          if (this.$store.getters.currentUser.om_sites.indexOf(shift[i].site) >= 0) {
            arr.push(shift[i].shift);
          }
        }
        if(arr.length > 3){
          return [arr[0] + ', ' + arr[1] + ', ' + arr[2], (arr.length - 3)]
        } else {
          return [arr.join(', '), ''];
        }
      }
    },
    getFromAsgSite(site){
      if (site.length == 0) {
        return 'N/A';
      }else{
        var arr = [];
        for (var i = 0; i < site.length; i++) {
          if (this.$store.getters.currentUser.om_sites.indexOf(site[i]._id) >= 0) {
            arr.push(site[i].site_name);
          }
        }

        return arr.join(', ');
        
      }
    },
    getFromAsgSite2(site){
      if (site.length == 0) {
        return 'N/A';
      }else{
        var arr = [];
        for (var i = 0; i < site.length; i++) {
          if (this.$store.getters.currentUser.om_sites.indexOf(site[i]._id) >= 0) {
            arr.push(site[i].site_name);
          }
        }

        if(arr.length > 3){
          return [arr[0] + ', ' + arr[1] + ', ' + arr[2], (arr.length - 3)]
        } else {
          return [arr.join(', '), ''];
        }
        
      }
    },
    openImage(img){
      if (img && img != 'null' && img != '') {
        this.isLoadingImg = true;
        this.isLoaded = false;
        this.img = img+'?'+ new Date();
        this.$refs['image-modal'].show();
      }

    },
    showOption(itemRole){
      var role = this.$store.getters.currentUser.role;

      if (role == 'administrator') {
        return true;
      }else{

        if (role == 'admin') {
          var arr = ['operation_manager', 'operation_executive','site_client','site_manager','supervisor','crew'];
          return arr.includes(itemRole);

        }else if (role == 'operation_manager') {
          var arr = ['supervisor','crew'];
          return arr.includes(itemRole);

        } else if (role == 'operation_executive') {
          var arr = ['supervisor', 'crew'];
          return arr.includes(itemRole);

        } else if (role == 'site_manager') {
          var arr = ['site_client'];
          return arr.includes(itemRole);

        }else if (role == 'site_client') {

          return false;

        }else if (role == 'supervisor') {
          var arr = ['crew'];
          return arr.includes(itemRole);
        }else if (role == 'crew') {
          
          return false;

        }

        
      }
    },
    showAddOption(){
      var role = this.$store.getters.currentUser.role;

      if (role == 'site_client' || role == 'crew') {
        return false;
      }else{
        return true;        
      }
    },
    showAlert(){
      return Swal.fire({
        position : 'center',
        icon     : 'error',
        title    : this.error_message,
        timer    : 1500,
        showConfirmButton : false
      });
    },
  },
  mounted(){
    this.allSites();
    this.getRoles();
    this.getRolesEditable();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
